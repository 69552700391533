// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <header>
        
  </header>
)
// {/* <Link to="/">{siteTitle}</Link> */}
// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
